<template>
  <nav class="side-nav">
    <collapsible-container>
      <ol
        v-if="data.data.value"
        class="side-nav__list">
        <li
          class="side-nav__item"
          :class="{'side-nav__item--active': data.data.value.root.active}">
          <nuxt-link
            :to="data.data.value.root.href"
            class="side-nav__link">
            <a-icon icon="fa:solid:house-chimney" />
            {{ data.data.value.root.name }} Home
          </nuxt-link>
        </li>

        <li
          v-for="item in data.data.value.items"
          :key="item.name"
          :class="{ 'side-nav__item--active': item.active }"
          class="side-nav__item">
          <nuxt-link
            :to="item.href"
            class="side-nav__link">
            {{ item.name }}
          </nuxt-link>
          <ol
            v-if="item.children?.length"
            class="side-nav__list">
            <li
              v-for="child in item.children"
              :key="child.name"
              :class="{ 'side-nav__item--active': child.active }"
              class="side-nav__item">
              <nuxt-link
                :to="child.href"
                class="side-nav__link">
                {{ child.name }}
              </nuxt-link>
            </li>
          </ol>
        </li>
      </ol>
    </collapsible-container>
  </nav>
</template>

<script setup lang="ts">
import type { ContentItem } from "@edco/apollo";
import { useApiFetch, useCurrentCulture } from "#imports";

const props = defineProps<{
  content : ContentItem
}>();

type SideNav = {
  root: SideNavItem,
  items?: SideNavItem[]
};

type SideNavItem = {
  name: string,
  href: string,
  active: boolean,
  children?: SideNavItem[]
};

const culture = useCurrentCulture();
const data = await useApiFetch<SideNav>( `/api/side-nav?key=${props.content.id}&culture=${culture.value}` );

</script>

<style lang="scss" scoped>
.side-nav {
  border-top: 1px solid #ccc;

  &__list {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  &__item {
    font-weight: 500;

    svg {
      display: inline-block;
      margin-right: 5px;
      vertical-align: text-top;
    }
  }

  &__item &__item {
    font-weight: 400;
  }

  &__link {
    display: block;
    padding: 7.5px 10px;
    text-decoration: none !important;
    color: var( --color-secondary-500 );
    border-bottom: 1px solid #ccc;

    &:hover {
      background: #f8f8f8;
      color: var( --color-secondary-500 );
    }
  }

  &__item &__item &__link {
    padding-left: 30px;
  }

  &__item--active > &__link {
    color: var( --color-primary-500 ) !important;
  }
}
</style>
