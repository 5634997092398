<template>
  <div class="collapsible">
    <div class="collapsible--mobile">
      <button
        v-bind="toggleAttrs"
        type="button"
        class="collapsible__toggle"
        @click="isExpanded = !isExpanded">
        Contents in this section

        <a-icon
          class="icon"
          icon="fa:regular:chevron-down"
          :rotation="!isExpanded ? undefined : 180" />
      </button>

      <Collapse
        v-bind="collapseAttrs"
        :when="isExpanded"
        class="collapse">
        <div class="collapse__content">
          <slot />
        </div>
      </Collapse>
    </div>

    <div class="collapsible--desktop">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Collapse } from "vue-collapsed";
import { ref } from "vue";

const isExpanded = ref( false );

const toggleAttrs = computed( () => ( {
  id: "toggle-id",
  "aria-controls": "collapse-id",
  "aria-expanded": isExpanded.value,
} ) );

const collapseAttrs = {
  role: "region",
  id: "collapse-id",
  "aria-labelledby": "toggle-id",
};

</script>

<style lang="scss" scoped>
.collapsible {
  margin-bottom: 15px;

  &__toggle {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    background: none;
    border: 0;
    padding: 7.5px 15px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    width: 100%;
    align-items: center;
    color: var( --color-secondary-500 );

    svg {
      display: inline-block;
      margin-left: 3px;
    }

    ::v-deep( .icon ) {
      transition: transform .2s cubic-bezier( 0.33, 1, 0.68, 1 );

      &.inverted {
        transform: rotate( 180deg );
      }
    }

    @media ( width >= 992px ) {
      display: none;
    }
  }

  .collapse {
    transition: height .2s cubic-bezier( 0.33, 1, 0.68, 1 );

    @media ( width >= 992px ) {
      transition-duration: 0s;
    }

    @media (prefers-reduced-motion ) {
      transition-duration: 0s;
    }

    &__content {
      overflow: hidden;
    }
  }

  &--mobile {
    @media ( width >= 992px ) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @media ( width >= 992px ) {
      display: block;
    }
  }
}
</style>
